import { NAMESPACES } from 'constants/locales'

import React, { memo, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { trackRedirectToPage, trackSignUp } from 'components/tracking/utils'
import { routes } from 'utils/routes'
import CtaModule from 'components/modules/cta-module'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import { CornersStyle } from 'components/modules/module-container'
import { Palette } from '@typeform/ginger/dist/constants/palettes'

import TrackingLocation, {
  TRACKING_LOCATIONS,
} from '../common/tracking-location'

const ClosingSection = () => {
  const { t, lang } = useTranslation(NAMESPACES.HOME)
  const secondaryLabel = t('homepage.closing.cta.secondary.label')
  const primaryLabel = t('homepage.closing.cta.primary.label')

  const handleSignupClick = useCallback(() => {
    trackSignUp({
      ctaText: primaryLabel,
      itemType: 'button',
      linkUrl: routes(lang).signup,
      location: TRACKING_LOCATIONS.ENDING,
    })
  }, [primaryLabel, lang])

  const handlePricingClick = useCallback(() => {
    trackRedirectToPage({
      label: secondaryLabel,
      linkUrl: routes(lang).pricing,
      location: TRACKING_LOCATIONS.ENDING,
    })
  }, [secondaryLabel, lang])

  return (
    <TrackingLocation name={TRACKING_LOCATIONS.ENDING}>
      <CtaModule
        cornersStyle={
          lang === LANGUAGE_CODES.english
            ? CornersStyle.Default
            : CornersStyle.RoundTop
        }
        data-testid='closing-section'
        heading={t('homepage.closing.title')}
        subheading={t('homepage.closing.body')}
        backgroundColor={NeutralColors.Ink}
        textColor={NeutralColors.White}
        palette={Palette.Negative}
        actionBar={{
          primaryLink: {
            label: primaryLabel,
            href: routes(lang).signup,
            onClick: handleSignupClick,
          },
          secondaryLink: {
            label: secondaryLabel,
            href: routes(lang).pricing,
            onClick: handlePricingClick,
          },
        }}
      />
    </TrackingLocation>
  )
}

ClosingSection.displayName = 'ClosingSection'

export default memo(ClosingSection)
